<!-- eslint-disable prettier/prettier -->
<template>
    <loading :active="load" :can-cancel="false" :is-full-page="true" color="#11592e"></loading>

    <div v-if="!load" class="cardsList-month">
        <CardComponent>
            <div class="month-container">
                <h2 class="month-title">{{ getMonth("Full", currentDate.getMonth() + 1) }} <span class="year-title">{{
                    currentDate.getFullYear() }}</span>
                </h2>
                <img src="@/assets/targets/fi-rr-pencil.svg" alt="pencil-icon" @click="openEditTarget()">
            </div>

            <ModalTargetCurrentMonth :valueCurrent="targetCurrentMonth.revenueCurrent" title="Receita (R$)"
                :targetCurrent="targetCurrentMonth.revenueTarget"
                :superTargetCurrent="targetCurrentMonth.revenueSuperTarget" urlImage="amount-icon" />

            <ModalTargetCurrentMonth title="Abastecimentos" :valueCurrent="targetCurrentMonth.fuelingCurrent"
                :targetCurrent="targetCurrentMonth.fuelingTarget"
                :superTargetCurrent="targetCurrentMonth.fuelingSuperTarget" urlImage="fuelTransactions-icon" />

            <ModalTargetCurrentMonth title="Galonagem (L)" :valueCurrent="targetCurrentMonth.gallonageCurrent"
                :targetCurrent="targetCurrentMonth.gallonageTarget"
                :superTargetCurrent="targetCurrentMonth.gallonageSuperTarget" urlImage="gallonage-icon" />
        </CardComponent>



        <template v-for="data in targetsData" :key=data.id>
            <CardComponent>
                <div class="month-container">
                    <h2 class="month-title">{{ getMonth("Full", getMonthOfDate(data.createdAt)) }} <span
                            class="year-title">{{
                                getYearOfDate(data.createdAt) }}</span>
                    </h2>
                </div>

                <!-- <ModalTargetContent :valueCurrent="data.registrationCurrent" title="Cadastros"
                    :target="data.registrationTarget" :superTarget="data.registrationSuperTarget" urlImage="add-user-icon" /> -->

                <ModalTargetContent :valueCurrent="data.revenueCurrent" title="Receita (R$)"
                    :target="data.revenueTarget" :superTarget="data.revenueSuperTarget" urlImage="amount-icon" />

                <ModalTargetContent title="Abastecimentos" :valueCurrent="data.fuelingCurrent"
                    :target="data.fuelingTarget" :superTarget="data.fuelingSuperTarget"
                    urlImage="fuelTransactions-icon" />

                <ModalTargetContent title="Galonagem (L)" :valueCurrent="data.gallonageCurrent"
                    :target="data.gallonageTarget" :superTarget="data.gallonageSuperTarget" urlImage="gallonage-icon" />
            </CardComponent>
        </template>
    </div>


    <ModalEditTargets :gasStationId="props.gasStationId" v-if="isOpenModalEdit" @cancel-modal="isOpenModalEdit = false"
        :update-cards="getDataCurrentMonth" />

</template>


<script setup>
import {
    ref,
    onMounted,
    defineProps,
    watch, defineAsyncComponent
} from "vue";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import { getMonthOfDate, getYearOfDate } from "./utils/formatDate"
import ModalEditTargets from "../ModalEditTargets/ModalEditTargets.vue";
import { getMonth } from "@/mixins";
import Loading from "vue3-loading-overlay";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { prop } from "vue-class-component";

const ModalTargetContent = defineAsyncComponent(
    () => import("./ModalTargetContent.vue")
);

const ModalTargetCurrentMonth = defineAsyncComponent(
    () => import("./ModalTargetCurrentMonth.vue")
);

const currentDate = new Date();
const gasStationController = new GasStationController();
const load = ref(true);
const props = defineProps({
    managerId: {
        type: Number,
        required: true,
    },
    periodStart: {
        type: String,
        required: true,
    },
    periodEnd: {
        type: String,
        required: true,
    },
    gasStationId: {
        type: Number,
        required: true,
    }
});



const managerDashboardService = new ManagerDashboardController(
    props.managerId,
    props.periodStart,
    props.periodEnd,
    props.gasStationId
);
const isOpenModalEdit = ref(false)
const targetsData = ref([])
const targetCurrentMonth = ref({})

const openEditTarget = () => {
    isOpenModalEdit.value = true
}

const getDataCurrentMonth = async () => {
    load.value = true
    await gasStationController.getTargetLastMonth(props.gasStationId)
        .then((response) => {
            targetCurrentMonth.value = response.data
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            load.value = false;
        });
}


const getData = async () => {
    load.value = true
    targetsData.value = await managerDashboardService.targetsData()
    load.value = false
};

watch(props, (newVal, oldVal) => {
    if (newVal.gasStationId) {
        getData();
        getDataCurrentMonth();
    }
});


onMounted(async () => {
    getDataCurrentMonth();
    getData();

});


</script>


<style lang="scss" scoped>
@import "./TargetsListMonth.scss";
</style>
